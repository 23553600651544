import revive_payload_client_YexZve7MjHihlJnHgiV3gBzUlQkgP0h0loqiL9v8jBI from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OhvFFCC6yNME3V4_2_PmfnxdJ4gbwnCNC9CAcvlqG0c from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kK7_rmocP8jnyJ1gv_uHqawmARMgyWNfyNFVwQUjk18 from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8UnGZXXdsj5K9VORBxNG_v4p9hpWVRogkvkCdxdRehY from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_terser_6bd7a4919558ca312f6a5814509edf2b/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8iKYdV_a9umXzO4zyuzcvt61tBRDT0_DemoGI6OfkLw from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YKzduX9bgLIsU0F_wP588zSSPGWmY_qr7Kc2Ah5wYdI from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xdnHfJtN_LoY0DMWYapCHNonhx9yxnyDzBQyKSKhuqk from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eh9t7v_7j3OYl_xrTcklTB9Zpoep6o886s5QglsowjE from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_kb7ia3Do2tEHnh9eQQN8wwSS_aQ6axsspYng8zvgG4A from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_nKA9eExGZMJ88jwJS_GBKmR5LHReTIaOt_FkDJTahO0 from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_terser@5.39._203ddf12d012fec74228050421501ab6/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8 from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_7Oli8fzrNIbdyrjI2z7xvf8BUiWdHPi2e015sM55NMY from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_TzTKasJjOcrPHX5tHbQmvG6ofI0fAkN8refvWmxAWbU from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_M1wY8pivT3tWJsGsRkGbxA1WyTDY0zLoI2IfYk6MoTE from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import ssg_detect_g8UH8lvCeSjg77pA5ySuUCkgCYINHaILJ11BHwUijC4 from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_YexZve7MjHihlJnHgiV3gBzUlQkgP0h0loqiL9v8jBI,
  unhead_OhvFFCC6yNME3V4_2_PmfnxdJ4gbwnCNC9CAcvlqG0c,
  router_kK7_rmocP8jnyJ1gv_uHqawmARMgyWNfyNFVwQUjk18,
  _0_siteConfig_8UnGZXXdsj5K9VORBxNG_v4p9hpWVRogkvkCdxdRehY,
  payload_client_8iKYdV_a9umXzO4zyuzcvt61tBRDT0_DemoGI6OfkLw,
  navigation_repaint_client_YKzduX9bgLIsU0F_wP588zSSPGWmY_qr7Kc2Ah5wYdI,
  check_outdated_build_client_xdnHfJtN_LoY0DMWYapCHNonhx9yxnyDzBQyKSKhuqk,
  chunk_reload_client_eh9t7v_7j3OYl_xrTcklTB9Zpoep6o886s5QglsowjE,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_kb7ia3Do2tEHnh9eQQN8wwSS_aQ6axsspYng8zvgG4A,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  plugin_nKA9eExGZMJ88jwJS_GBKmR5LHReTIaOt_FkDJTahO0,
  plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg,
  plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8,
  switch_locale_path_ssr_7Oli8fzrNIbdyrjI2z7xvf8BUiWdHPi2e015sM55NMY,
  route_locale_detect_TzTKasJjOcrPHX5tHbQmvG6ofI0fAkN8refvWmxAWbU,
  i18n_M1wY8pivT3tWJsGsRkGbxA1WyTDY0zLoI2IfYk6MoTE,
  base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE,
  ssg_detect_g8UH8lvCeSjg77pA5ySuUCkgCYINHaILJ11BHwUijC4
]